// import { ThemeModeToggler } from '@percihealth/react';
import styles from './Footer.module.css';

export default function Footer() {
  return (
    <footer className={'grid-outer'}>
      <div className={styles.footer}>
        <div className={styles['left-block']}>
          ©&nbsp;2023 Perci Health. All rights reserved.
        </div>
        {/* <div className={styles['right-block']}>
        <ThemeModeToggler />
      </div> */}
      </div>
    </footer>
  );
}
